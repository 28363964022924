@import "./positionings";

/* Global Styles */
:root {
	--primary-color: #17a2b8;
	--dark-color: #343a40;
	--light-color: #f4f4f4;
	--danger-color: #dc3545;
	--success-color: #28a745;

	--white-color: #ffffff;
	--grey-color: #8a8685;
	--cyan-color: #74d2ff;
	--red-color: #e63300;
	--black-color: #1d1d1d;
	--black-color: #232323;
}

$theme-colors: (
	"primary": #17a2b8,
	"dark": #0b132a,
	"light": #efeff1,
	"danger": #e63400,
	"success": #28a745,
	"secondary": #7B808C,
);

@each $color, $value in $theme-colors {
	.btn-outline-#{$color} {
		border-width: 2.5px;
		padding: 0 1rem;
	}
}

button,
span {
	font-family: system-ui;
}

.main-container {
	margin: auto;

	&.bottom-bar-hidden {
		height: calc(100vh - 70px - 15px);
	}
}

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	outline: none;
}

html {
  scroll-behavior: smooth;
}

body {
	font-family: system-ui;
	font-size: 1rem;
	line-height: 1.6;
	background-color: #fff;
	color: #333;
	overscroll-behavior: none;
	overflow-x: hidden;
	width: 100vw;
	overscroll-behavior-y: contain;
}

a {
	color: var(--primary-color);
	text-decoration: none;
}

ul {
	list-style: none;
}

img {
	width: 100%;
}

/* Text Styles*/
.x-large {
	font-size: 4rem;
	line-height: 1.2;
	margin-bottom: 1rem;
}

.large {
	font-size: 3rem;
	line-height: 1.2;
	margin-bottom: 1rem;
}

.lead {
	font-size: 1.5rem;
	margin-bottom: 1rem;
}

.text-center {
	text-align: center;
}

/* Padding */
.p {
	padding: 0.5rem;
}
.p-1 {
	padding: 1rem;
}
.p-2 {
	padding: 2rem;
}
.p-3 {
	padding: 3rem;
}
.py {
	padding: 0.5rem 0;
}
.py-1 {
	padding: 1rem 0;
}
.py-2 {
	padding: 2rem 0;
}
.py-3 {
	padding: 3rem 0;
}

/* Margin */
.m {
	margin: 0.5rem;
}
.m-1 {
	margin: 1rem;
}
.m-2 {
	margin: 2rem;
}
.m-3 {
	margin: 3rem;
}
.my {
	margin: 0.5rem 0;
}
.my-1 {
	margin: 1rem 0;
}
.my-2 {
	margin: 2rem 0;
}
.my-3 {
	margin: 3rem 0;
}

// .btn {
// 	display: inline-block;
// 	background: var(--light-color);
// 	color: #333;
// 	padding: 0.4rem 1.3rem;
// 	font-size: 1rem;
// 	border: none;
// 	cursor: pointer;
// 	margin-right: 0.5rem;
// 	transition: opacity 0.2s ease-in;
// 	outline: none;
// }

.alert {
	padding: 0.8rem;
	margin: 1rem 0;
	opacity: 0.9;
	background: var(--light-color);
	color: #333;
}

.bg-primary,
.alert-primary {
	background: var(--primary-color);
	color: #fff;
}

.btn-light,
.bg-light,
.alert-light {
	background: var(--light-color);
	color: #333;
}

.btn-dark,
.bg-dark,
.alert-dark {
	background: var(--dark-color);
	color: #fff;
}

.btn-danger,
.bg-danger,
.alert-danger {
	background: var(--danger-color);
	color: #fff;
}

.btn-success,
.bg-success,
.alert-success {
	background: var(--success-color);
	color: #fff;
}

.btn-white,
.bg-white,
.alert-white {
	background: #fff;
	color: #333;
	border: #ccc solid 1px;
}

.btn:hover {
	opacity: 0.8;
}

.bg-light {
	border: #ccc solid 1px;
}

.round-img {
	border-radius: 50%;
}

.line {
	height: 1px;
	background: #ccc;
	margin: 1.5rem 0;
}

/* Overlay */
.dark-overlay {
	background-color: rgba(0, 0, 0, 0.7);
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

/* Forms */
.form .form-group {
	margin: 1.2rem 0;
}

.form .form-text {
	display: block;
	margin-top: 0.3rem;
	color: #888;
}

.form input[type="text"],
.form input[type="email"],
.form input[type="password"],
.form input[type="date"],
.form select,
.form textarea {
	display: block;
	width: 100%;
	padding: 0.4rem;
	font-size: 1.2rem;
	border: 1px solid #ccc;
}

.form input[type="submit"],
button {
	font: inherit;
}

.form .social-input {
	display: flex;
}

.form .social-input i {
	padding: 0.5rem;
	width: 4rem;
}

.table th,
.table td {
	padding: 1rem;
	text-align: left;
}

.table th {
	background: var(--light-color);
}

/* Landing Page */
.landing {
	position: relative;
	background: url("./img/showcase.jpg") no-repeat center center/cover;
	height: 100vh;
}

.landing-inner {
	color: #fff;
	height: 100%;
	width: 80%;
	margin: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
}

/* Profiles Page */
.profile {
	display: grid;
	grid-template-columns: 2fr 4fr 2fr;
	align-items: center;
	grid-gap: 2rem;
	padding: 1rem;
	line-height: 1.8;
	margin-bottom: 1rem;
}

/* Profile Page */
.profile-grid {
	display: grid;
	grid-template-areas:
		"top top"
		"about about"
		"exp edu"
		"github github";
	grid-gap: 1rem;
}

.profile-top {
	grid-area: top;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
}

.profile-top img {
	width: 250px;
	height: 250px;
	background-color: white;
}

.profile-top .icons a {
	color: #fff;
	margin: 0 0.3rem;
}

.profile-top .icons a:hover {
	color: var(--dark-color);
}

.profile-about {
	grid-area: about;
	text-align: center;
}

.profile-about .skills {
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
}

.profile-exp {
	grid-area: exp;
}

.profile-edu {
	grid-area: edu;
}

.profile-exp h2,
.profile-edu h2 {
	margin-bottom: 1rem;
}

.profile-exp > div,
.profile-edu > div {
	margin-bottom: 1rem;
	padding-bottom: 1rem;
	border-bottom: #ccc 1px dotted;
}

.profile-exp > div:last-child,
.profile-edu > div:last-child {
	border: 0;
}

.profile-exp p,
.profile-edu p {
	margin: 0.5rem 0;
}

.profile-github {
	grid-area: github;
}

.profile-github .repo {
	display: flex;
}

.profile-github .repo > div:first-child {
	flex: 7;
	flex-basis: 70%;
}

.profile-github > div:last-child {
	flex: 3;
	flex-basis: 20%;
}

/* Posts Page */
.post-form .post-form-header {
	background: var(--primary-color);
	padding: 0.5rem;
}

.post {
	display: grid;
	grid-template-columns: 1fr 4fr;
	grid-gap: 2rem;
	align-items: center;
}

.post > div:first-child {
	text-align: center;
}

.post img {
	width: 100px;
}

.post .comment-count {
	background: var(--light-color);
	color: var(--primary-color);
	padding: 0.1rem 0.2rem;
	border-radius: 5px;
	font-size: 0.8rem;
}

.post .post-date {
	color: #aaa;
	font-size: 0.8rem;
	margin-bottom: 0.5rem;
}

/* Mobile Styles */
@media (max-width: 700px) {
	.container {
		// margin-top: 8rem;
	}

	.hide-sm {
		display: none;
	}

	/* Text Styles */
	.x-large {
		font-size: 3rem;
	}

	.large {
		font-size: 2rem;
	}

	.lead {
		font-size: 1rem;
	}

	/* Profiles Page */
	.profile {
		grid-template-columns: 1fr;
		text-align: center;
	}

	.profile ul {
		display: none;
	}

	/* Profile Page */

	.profile-top img,
	.profile img {
		width: 200px;
		margin: auto;
	}

	.profile-grid {
		grid-template-areas:
			"top"
			"about"
			"exp"
			"edu"
			"github";
	}

	.profile-about .skills {
		flex-direction: column;
	}

	.dash-buttons a {
		display: block;
		width: 100%;
		margin-bottom: 0.2rem;
	}

	.post {
		grid-template-columns: 1fr;
	}
	.post a,
	.post button {
		padding: 0.3rem 0.4rem;
	}
}

/* Upload */

.Dropzone {
	height: 200px;
	width: 200px;
	background-color: #fff;
	border: 2px dashed rgb(187, 186, 186);
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	font-size: 16px;
}

.Highlight {
	background-color: rgb(188, 185, 236);
}

.Icon {
	opacity: 0.3;
	height: 64px;
	width: 64px;
}

.FileInput {
	display: none;
}

.ProgressBar {
	width: 100%;
	height: 8px;
	background-color: rgb(183, 155, 229);
	border-radius: 5px;
}

.Progress {
	background-color: rgba(103, 58, 183, 1);
	height: 100%;
	margin: 0;
	border-radius: 5px;
}

.Upload {
	display: flex;
	flex-direction: column;
	flex: 1;
	align-items: flex-start;
	text-align: left;
	overflow: hidden;
}

.Content {
	display: flex;
	flex-direction: row;
	padding-top: 16px;
	box-sizing: border-box;
	width: 100%;
}

.Files {
	margin-left: 32px;
	align-items: flex-start;
	justify-items: flex-start;
	flex: 1;
	overflow-y: auto;
}

.Actions {
	display: flex;
	flex: 1;
	width: 100%;
	align-items: flex-end;
	flex-direction: column;
	margin-top: 32px;
}

.Title {
	margin-bottom: 32px;
	color: #555;
}

.Filename {
	margin-bottom: 8px;
	font-size: 16px;
	color: #555;
}

.Row {
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: space-between;
	height: 50px;
	padding: 8px;
	overflow: hidden;
	box-sizing: border-box;
}

.CheckIcon {
	opacity: 0.5;
	margin-left: 32px;
}

.ProgressWrapper {
	display: flex;
	flex: 1;
	flex-direction: row;
	align-items: center;
}

button {
	font-family: "Roboto medium", sans-serif;
	font-size: 14px;
	display: inline-block;
	padding: 6px 16px;
	line-height: 1.42857143;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	-ms-touch-action: manipulation;
	touch-action: manipulation;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	border: 0;
	border-radius: 2px;
	background: rgba(103, 58, 183, 1);
	color: #fff;
	outline: 0;
}

button:disabled {
	background: rgb(189, 189, 189);
	cursor: default;
}

.btn-lg-red {
	height: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 30px;
	background: #e63400;
	border-radius: 10px;

	font-family: Roboto;
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	color: white;
}

.btn-lg-blue {
	height: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 30px;
	background: #2f5597;
	border-radius: 10px;

	font-family: Roboto;
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	color: white;
}

.btn-lg-yellow {
	height: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 30px;
	background: #f5ce1f;
	border-radius: 10px;

	font-family: Roboto;
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	color: white;
}

.Card {
	background-color: white;
	padding: 32px;
	width: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11), 0 5px 15px 0 rgba(0, 0, 0, 0.08);
	box-sizing: border-box;
}

.App {
	text-align: center;
	background-color: rgb(206, 213, 223);
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
}

.horizontal {
	display: flex;
	align-items: center;
}

.friend-request-wrapper {
	margin-top: 20px;
}

.friend-request-container {
	max-height: 650px;
	min-width: 200px;
	border: 1px solid lightgray;
	padding: 20px;
	overflow: scroll;
}

.stage-link {
	display: inline-block;
}

.error-container {
	border: 1px solid #ec0a0a;
	background-color: rgba(236, 10, 10, 0.4);
	border-radius: 4px;
	line-height: 3;
	text-align: center;
	margin: 20px 0;
}

.input-file {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.upload-image-btn {
	margin-left: 15px;
}

.upload-image-btn:disabled {
	cursor: not-allowed;
}

.donation-container {
	margin: 20px 0;
}

.donation-container input {
	line-height: 2;
	padding: 5px;
	margin-left: 10px;
}

.donation-container .donation-checkout-container {
	display: inline-block;
	margin-left: 20px;
}

.stripe-payment-wrapper {
	position: relative;
	width: 100%;
	max-width: 500px;
	border-radius: 10px;
	font-size: 12px;
	background-color: #f6f6f6;
	padding: 15px;
}

.card-details-wrapper {
	border: 1px solid #e4e4e4;
	border-radius: 5px;
	padding: 10px;
	width: fit-content;
	margin-bottom: 10px;
}

.loading-gif {
	width: 20px !important;
	height: 20px !important;
	background-color: transparent !important;
}

/* Stripe CSS */
.stripe-payment-wrapper .DemoPickerWrapper {
	padding: 0 12px;
	font-family: "Source Code Pro", monospace;
	box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
	border-radius: 3px;
	background: white;
	margin: 24px 0 48px;
	width: 100%;
}

.stripe-payment-wrapper .DemoPicker {
	font-size: 18px;
	border-radius: 3px;
	background-color: white;
	height: 48px;
	font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
	border: 0;
	width: 100%;
	color: #32325E;
	outline: none;
	background: transparent;

	-webkit-appearance: none;
}

.stripe-payment-wrapper .DemoWrapper {
	margin: 0 auto;
	max-width: 500px;
	padding: 0 24px;
	display: flex;
	flex-direction: column;
	height: 100vh;
}

.stripe-payment-wrapper .Demo {
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding-bottom: 40%;
}

.stripe-payment-wrapper label {
	color: #6b7c93;
	font-weight: 300;
	letter-spacing: 0.025em;
}

.stripe-payment-wrapper button {
	white-space: nowrap;
	border: 0;
	outline: 0;
	display: inline-block;
	height: 40px;
	line-height: 40px;
	padding: 0 14px;
	box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
	color: #fff;
	border-radius: 4px;
	font-size: 15px;
	font-weight: 600;
	text-transform: uppercase;
	letter-spacing: 0.025em;
	background-color: #32325e;
	text-decoration: none;
	-webkit-transition: all 150ms ease;
	transition: all 150ms ease;
	margin-top: 10px;
}

.stripe-payment-wrapper button:hover {
	color: #fff;
	cursor: pointer;
	background-color: #7676b6;
	transform: translateY(-1px);
	box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}

.stripe-payment-wrapper input[type="text"],
.stripe-payment-wrapper .StripeElement {
	display: block;
	margin: 10px 0 20px 0;
	max-width: 500px;
	padding: 10px 14px;
	font-size: 1em;
	font-family: "Source Code Pro", monospace;
	box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
	border: 0;
	outline: 0;
	border-radius: 4px;
	background: white;
}

.stripe-payment-wrapper input[type="text"]::placeholder {
	color: #aab7c4;
}

.stripe-payment-wrapper input[type="text"]:focus,
.stripe-payment-wrapper .StripeElement--focus {
	box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
	-webkit-transition: all 150ms ease;
	transition: all 150ms ease;
}

.stripe-payment-wrapper .StripeElement.IdealBankElement,
.stripe-payment-wrapper .StripeElement.FpxBankElement,
.stripe-payment-wrapper .StripeElement.PaymentRequestButton {
	padding: 0;
}

.stripe-payment-wrapper .StripeElement.PaymentRequestButton {
	height: 40px;
}

.stripe-payment-wrapper input[type="checkbox"] {
	margin-right: 10px;
}

.livestream.str-chat.dark .str-chat__header-livestream {
	z-index: 0;
}

.one-one-chat-container {
	height: 700px;
	position: relative;
	overflow: hidden;

	.str-chat {
		height: inherit;

		.str-chat__avatar--circle a {
			width: 100%
		}
	}

	.str-chat__channel-preview-messenger {
		padding: 30px;
		color: #000;

		&--left {
			.str-chat__avatar-fallback {
				background-image: url("./assets/images/no-profile-picture.png");
				background-size: contain;
			}
		}
	}
}

@media screen and (max-width: 960px) {
	.one-one-chat-container {
		.str-chat-channel-list.messaging, .str-chat-channel-list.team, .str-chat-channel-list.commerce {
			position: absolute;
				min-height: inherit;
		}

		.str-chat-channel-list--open.messaging, .str-chat-channel-list--open.team, .str-chat-channel-list--open.commerce {
			left: 15px;
		}
	}
}

.stripe-payment-wrapper {
	label {
		width: 100%;
	}

	.stripe-content-room-logo {
		text-align: center;
		margin-bottom: 20px;
		position: relative;

		img {
			width: 150px;
		}

		.powered-by-stripe {
			position: absolute;
			bottom: 5px;
			img {
				width: 100px;
				height: auto;
			}
		}
	}
}

.custom-link-button-wrapper {
	a {
	text-decoration: none;
	}

	a:hover,
	a:visited,
	a:invalid {
	text-decoration: none;
	}
}

.spacer {
	height: 20px;
}

.btn-primary-content {
	background: #e63400;
	box-shadow: 0px 3.5px 10px rgba(245, 56, 56, 0.5);
	border-radius: 10px;

	color: #FFFFFF;

	font-family: Roboto;
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	line-height: 23px;

	@media (max-width: 414px) {
		font-size: 16px;
		line-height: 19px;
		white-space: pre-wrap;
	}

	&__title {
		color: #FFFFFF;

		font-family: Roboto;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		line-height: 23px;
	}
	&:hover {
		color: #FFFFFF;
	}
	&:active {
		color: #FFFFFF;
	}
	&:focus {
		color: #FFFFFF;
	}
}

.btn-secondary-content {
	background: #EAF8FF;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 6px;
	border: 1.5px solid #000000;
	color: #000000;
	width: 100%;
	
	font-family: Roboto;
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	line-height: 21px;
  padding-top: 10px;
  padding-bottom: 10px;
	margin-bottom: 10px;
	@media (max-width: 414px) {
		font-size: 16px;
		line-height: 19px;
		white-space: pre-wrap;
	}

	&:hover {
		opacity: 1;
	}
}

.hidden {
	display: none;
}

@media only screen and (max-width: 720px) {
	.container {
		max-width: unset;

		.mobile-full-width {
			margin-left: -15px;
			margin-right: -15px;
		}
	}

	.one-one-chat-container {
		height: calc(100vh - 265px);
		padding: unset;

		&.bottom-bar-hidden {
			height: calc(100vh - 185px);
		}

		.str-chat-channel-list--open.messaging, .str-chat-channel-list--open.team, .str-chat-channel-list--open.commerce {
			left: 0;
		}
	}
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
	overflow-y: auto;
}

input,
textarea {
	appearance: none;
	-webkit-appearance: none;
}

.unlock-protocol-checkout {
	background-color: rgba(0, 0, 0, 0.5);
}

.carousel-custom-class>[data-arrow=right]>button {
	width: 40px;
	border: none;
	cursor: pointer;
	height: 40px;
	outline: 0;
	position: absolute;
	top: 50%;
	right: 10px;
	transform: translate(0, -50%);
	z-index: 100;
	background-color: rgba(0,0,0,0.6);
	border-radius: 50%;

	&::after {
		content: '\003E';
	}
}

.carousel-custom-class>[data-arrow=left]>button {
	width: 40px;
	border: none;
	cursor: pointer;
	height: 40px;
	outline: 0;
	position: absolute;
	top: 50%;
	left: 10px;
	z-index: 100;
	background-color: rgba(0,0,0,0.6);
	border-radius: 50%;
	transform: translate(0, -50%);

	&::after {
		content: '\003C';
	}
}