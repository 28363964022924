.notification-container {
  width: 97%;
  height: calc(100vh - 270px);
  position: absolute;
  top: calc(-100vh + 250px);
  left: 50%;
  z-index: 1;
  padding: 10px;
  box-shadow: 0px 5px 12px 0px #7e7e7e;
  transform: translate(-50%, 0);

  .card-title {
    display: flex;
    justify-content: space-between;
  }

  .notification-close {
    cursor: pointer;
  }
}

@media (min-width: 768px) {
  .notification-container {
    width: 500px;
    height: calc(100vh - 130px);
    top: 110px;
    right: 10px;
    left: unset;
    box-shadow: 0px 5px 12px 0px #7e7e7e;
    transform: unset;
  }
}