.nav-wrapper {
  width: 100%;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  -webkit-position: sticky;
  height: 102px;
  top: -16px;
  background-color: #fff;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}

.nav-wrapper::before,
.nav-wrapper::after {
  content: '';
  display: block;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  -webkit-position: sticky;
  height: 16px;
}

.nav-wrapper::before {
  top: 70px;
  box-shadow: 0px 5.5px 7px rgba(0, 0, 0, 0.2);
}

.nav-wrapper::after {
  background: linear-gradient(white 10%, rgba(255,255,255,0.8) 50%, rgba(255,255,255,0.4) 100%, transparent);
  top: 0px;
  /* cover should fall over shadow */
  z-index: 2;
}

.nav-wrapper >nav {
  height: 86px;
  position: sticky;
  -webkit-position: sticky;
  top: 0px;
  margin-top: -16px;
  z-index: 3;
}

/* NAVIGATION */

.navbar {
  display: grid;
  grid-template-columns: 1fr 3fr;
  align-items: center;
  overflow: hidden;
  max-width: 1440px;

  .beta-badge {
    position: absolute;
    bottom: 12px;
    left: 165px;
  }
}

.navbar img {
  height: 70px;
  width: auto;
  justify-self: start;
}

.navbar ul {
  list-style: none;
  display: grid;
  justify-self: end;

  &.grid-col-5 {
    grid-template-columns: repeat(5, 1fr);
  }

  &.grid-col-2 {
    grid-template-columns: repeat(2, 1fr);
  }
}

.nav-item {
  display: flex;
  align-items: center;
  justify-content: center;

  min-width: 120px;

  a {
    font-family: Roboto;
    font-weight: bold;
    font-size: 18px;
    text-decoration: none;
    transition: color 0.3s ease-out;

    .svg-inline--fa {
      font-size: 25px;
    }
  }
}

.nav {
  transform: translate(0);
  transition: transform 0.3s ease-in-out;
}

.navbar-default {
  .auth-nav-sidebar {
    display: none;
  }
  .auth-nav-default {
    .nav-item {
      min-width: 100px;

      a {
        padding: 10px 30px;

        .svg-inline--fa {
          color: #424242;
        }

        &.active {
          .svg-inline--fa {
            color: #000000;
          }
        }

        &:hover {
          background: #ededed;
          border-radius: 10px;
        }
      }

      .cr-box {
        width: 30px;
        height: 30px;
      }
    }
  }
}

.guest-nav-default {
  .nav-item {
    .btn {
      padding: 8px 12.5px;
      min-width: 110px;
    }
  }
}

/* MOBILE MENU & ANIMATION */

.menu-toggle .bar {
  width: 25px;
  height: 3px;
  background-color: #3f3f3f;
  margin: 5px auto;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.menu-toggle {
  justify-self: end;
  display: none;
}

.menu-toggle:hover {
  cursor: pointer;
}

#mobile-menu.is-active .bar:nth-child(2) {
  opacity: 0;
}

#mobile-menu.is-active .bar:nth-child(1) {
  -webkit-transform: translateY(8px) rotate(45deg);
  -ms-transform: translateY(8px) rotate(45deg);
  -o-transform: translateY(8px) rotate(45deg);
  transform: translateY(8px) rotate(45deg);
}

#mobile-menu.is-active .bar:nth-child(3) {
  -webkit-transform: translateY(-8px) rotate(-45deg);
  -ms-transform: translateY(-8px) rotate(-45deg);
  -o-transform: translateY(-8px) rotate(-45deg);
  transform: translateY(-8px) rotate(-45deg);
}

.nav-backdrop {
  width: 100%;
  position: fixed;
  height: 100%;
  top: 70px;
  left: 0;
  background: #000000;
  opacity: 0.5;
  display: none;
  z-index: -2;
}

.navbar-dark {
  .nav-backdrop {
    top: 76px;
    &.show {
      display: block;
    }
  }
}

.navbar-dark {
  width: 75%;
  background-color: #1a1a1a;
  color: white;

  .navbar {
    background: rgba(255, 255, 255, 0.03);
    grid-template-columns: 50px auto;
    justify-content: unset;

    .cr-box {
      width: 36px;
      height: 36px;
    }

    img {
      height: 60px;
    }

    ul {
      display: flex;
      flex-direction: column;
      position: fixed;
      justify-content: start;
      top: 75px;
      left: 0;
      background-color: #1a1a1a;
      width: 20%;
      height: calc(100vh - 55px);
      transform: translate(-101%);
      text-align: center;
      overflow: hidden;
      box-shadow: 3.5px 5px 10px rgba(0, 0, 0, 0.2);
    }

    li {
      padding: 15px;

      &:first-child {
        margin-top: 50px;
      }

      a {
        font-size: 1.2rem;
      }
    }

    &.container {
      max-width: 100%;
    }

    .dark-logo-container {
      order: 2;

      .beta-badge {
        left: 148px;
        bottom: 6px;
      }
    }

    .menu-toggle {
      order: 1;
      justify-self: start;
    }
  }

  .menu-toggle,
  .bar {
    display: block;
    cursor: pointer;
  }

  .mobile-nav {
    transform: translate(0%) !important;
    background: #1a1a1a;
  }

  ul {
    li {
      a {
        color: white;
      }
    }
  }

  &.nav-wrapper {
    height: unset;
    top: 0;
    >nav {
      height: unset;
      margin-top: 0;
    }
  }

  &.nav-wrapper::before,
  &.nav-wrapper::after {
    display: none;
  }
}

.notification-circle {
  color: white;
  background: #e63400;
  position: absolute;
  font-size: 12px;
  top: 5px;
  right: 10px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.auth-nav-sidebar {
  .notification-circle {
    top: -12px;
    left: 15px;
    right: unset;
  }
}

/* Media Queries */

/* Mobile Devices - Phones/Tablets */

@media only screen and (max-width: 720px) {
  /* MOBILE NAVIGATION */
  .nav-wrapper {
    box-shadow: none;
    height: 86px;
  }

  .nav-wrapper::before {
    top: 54px;
  }

  .nav-wrapper >nav {
    height: 70px;
  }
  .navbar {
    grid-template-columns: 1fr 3fr;
    padding: 0.2rem 1rem;

    .beta-badge {
      left: 120px;
      bottom: 3px;
    }
  }
  .navbar img {
    height: 50px;
  }

  .navbar .auth-nav-sidebar {
    display: flex;
    flex-direction: column;
    position: fixed;
    justify-content: start;
    top: 70px;
    right: 0;
    background-color: #fff;
    width: 55%;
    height: calc(100vh - 55px);
    transform: translate(101%);
    text-align: center;
    overflow: hidden;
    z-index: -1;
  }

  .navbar li {
    padding: 15px;
    z-index: 999;
  }

  .navbar {
    .guest-nav-default {
      li {
        margin-top: 0;
      }
    }
  }

  .navbar li a {
    font-size: 1.2rem;
  }

  .menu-toggle,
  .bar {
    display: block;
    cursor: pointer;
  }

  .mobile-nav {
    transform: translate(0%) !important;
  }

  .nav-backdrop {
    &.show {
      display: block;
    }
  }

  .navbar-default {
    .auth-nav-sidebar {
      display: block;
    }

    .menu-toggle {
      display: none;
    }
  }
}

@media screen and (max-width: 992px) {
  .navbar-dark {
    width: 100%;

    .navbar {
      background: unset;
      grid-template-columns: 1fr 3fr 1fr;
      .dark-logo-container {
        text-align: center;

        .beta-badge {
          left: 50%;
          transform: translate(55px, 0);
          bottom: 0;
        }
      }

      img {
        height: 45px;
      }

      ul {
        width: 35%;
        top: 50px;
      }
    }

    .nav-backdrop {
      top: 61px;
    }
  }

  .navbar {
    .mobile-nav ul{
      width: 35%;
    }
  }
}

@media screen and (max-width: 430px) {
  .navbar {
    ul {
      width: 60%;
    }
  }

  .guest-nav-default {
    .nav-item {
      .btn {
        min-width: 65px;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .nav-wrapper {
    &.hide-navbar-on-mobile {
      display: none;
    }
  }
  .navbar ul.auth-nav-default {
    display: none;
  }

  .navbar ul.guest-nav-default {
    width: unset;

    li:first-child {
      margin-right: 5px;
    }

    &.grid-col-2 {
      grid-template-columns: repeat(2, 50%);
    }

    .nav-item {
      min-width: unset;
      padding: 0;

      .btn {
        font-size: 14px;
      }
    }
  }
}

