.footer-wrapper {
  width: 100%;
  position: fixed;
  bottom: 0;
  padding: 0px;
  background-color: #ffffff;
  border-top: 1px solid #c5c5c5;
  z-index: 101;
  
  .navbar {
    display: block;
    padding: 0;
    ul {
      display: grid;
      list-style: none;
      justify-self: end;
      grid-template-columns: repeat(5, 1fr);
      width: 100%;
    }

    .grid-col-2 {
      grid-template-columns: repeat(2, 50%);
    }

    .nav {
      .nav-item {
        margin: 0;
        padding: 0;
        min-width: 60px;

        .svg-inline--fa {
          font-size: 25px;
          color: #424242;
        }

        a {
          padding: 17px 20px;
          &.active {
            .svg-inline--fa {
              color: #000000;
            }
          }
        }

        .notification-circle {
          color: white;
          background: #e63400;
          position: absolute;
          font-size: 12px;
          top: 8px;
          right: 10px;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .cr-box {
          width: 30px;
          height: 30px;
        }
      }
    }
  }

  &.hide {
    display: none;
  }

  &.bottom-bar-hidden {
    padding-bottom: 30px;
  }
}

@media (min-width: 768px) {
  .footer-wrapper {
    display: none;
  }
}
