.spinner-container {
  position: absolute;
  top: 0;
  left: 0;
	width: 100%;
	height: 100%;
	z-index: 111;
	backdrop-filter: blur(3px);
}
@keyframes ldio-hmispynwpe {
  0% { transform: rotate(0) }
  100% { transform: rotate(360deg) }
}
.ldio-hmispynwpe div { box-sizing: border-box!important }
.ldio-hmispynwpe > div {
  position: absolute;
  width: 64px;
  height: 64px;
  top: 68px;
  left: 68px;
  border-radius: 50%;
  border: 8px solid #000;
  border-color: #f53838 transparent #f53838 transparent;
  animation: ldio-hmispynwpe 1s linear infinite;
}
.ldio-hmispynwpe > div:nth-child(2) { border-color: transparent }
.ldio-hmispynwpe > div:nth-child(2) div {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotate(45deg);
}
.ldio-hmispynwpe > div:nth-child(2) div:before, .ldio-hmispynwpe > div:nth-child(2) div:after { 
  content: "";
  display: block;
  position: absolute;
  width: 8px;
  height: 8px;
  top: -8px;
  left: 20px;
  background: #f53838;
  border-radius: 50%;
  box-shadow: 0 56px 0 0 #f53838;
}
.ldio-hmispynwpe > div:nth-child(2) div:after { 
  left: -8px;
  top: 20px;
  box-shadow: 56px 0 0 0 #f53838;
}
.loadingio-spinner-dual-ring-s0cequy1keb {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
	background: transparent;
	position: absolute;
	top: 25%;
	left: 50%;
	transform: translate(-50%);
}
.ldio-hmispynwpe {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-hmispynwpe div { box-sizing: content-box; }